import React from "react";
import ArticleBusinessComponent from "../components/caseStudyComponent/articleBusinessComponent";
import ArticleHomeComponent from "../components/caseStudyComponent/articleHomeComponent";
import ImprovementsComponent from "../components/caseStudyComponent/improvementsBusinessComponent";
import Footer from "../components/footerComponent";
import Header from "../components/headerComponent";
import Layout from "../components/layout";
import { graphql } from 'gatsby';
const BusinessDeveloperUseCase = (props) =>{
     const {
       data: {
         wpPage: {
           seo,
            article :{
      articleTheBlueprintTitle,
      articleTheBlueprintText,
      articleTheBlueprintSubDescription,
      articleTheBlueprintDescription,
    },
           articleHomeSection: {
             articleHomeTitle,
             articleHomeImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: articleHomeImage },
               },
             },
           },
           articleBusinessSection: {
             articleBusinessTitle,
             articleBusinessSubtitle,
             articleBusinessDescription,
             articleBusinessText,
           },
           articleChallengeSection: {
             articleChallengeTitle,
             articleChallengeText,
             articleChallengeSubText,
             articleChallengeDescription,
             articleChallengeSubDescription,
             articleChallengeImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: articleChallengeImage },
               },
             },
           },
           articleMotivation: {
             articleMotivationTitle,
             articleMotivationText,
             articleMotivationDescription,
           },
           articleImprovementsSection: {
             articleImprovementsTitle,
             articleImprovementsText,
             articleImprovementsTextCol,
             articleImprovementsSubText,
             articleImprovementsSubDescriptionCol,
             articleImprovementsSubDescription,
             articleImprovementsDescriptionHealthcare,
             articleImprovementsDescriptionCol,
             articleImprovementsDescription,
             articleImprovementsImage: {
               localFile: {
                 childImageSharp: { gatsbyImageData: articleImprovementsImage },
               },
             },
           },
           articleOutcomeSection: {
             articleOutcomeTitle,
             articleOutcomeText,
             articleOutcomeDescription,
             articleOutcomeSubtext,
           },
         },
       },
       pageContext: { title },
     } = props;
    return (
      <Layout title={title} seo={seo}>
        <Header />
        <ArticleHomeComponent
          articleTitle={articleHomeTitle}
          articleBackgroundImage={articleHomeImage}
        />
        <ArticleBusinessComponent
          articleTitle={articleBusinessTitle}
          articleText={articleBusinessSubtitle}
          articleDescription={articleBusinessDescription}
          articleSubText={articleBusinessText}
        />
        <ImprovementsComponent
          improvementsTitle={articleChallengeTitle}
          improvementsDescriptionCol={articleChallengeDescription}
          improvementsSubDescriptionCol={articleChallengeSubDescription}
          improvementsText={articleChallengeText}
          improvementsSubText={articleChallengeSubText}
          ImprovementsImage={articleChallengeImage}
          isBusinessDeveloper={true}
        />
        <ArticleBusinessComponent
          articleTitle={articleMotivationTitle}
          articleText={articleMotivationText}
          articleDescription={articleMotivationDescription}
          articleSubText="To deal with all of these obstacles and successfully do their job, employees need successful collaboration and consensus within the organization. Unfortunately, achieving effective collaboration in an organization is never easy. While getting everyone on board the traditional way may seem like the most obvious solution, business leaders need to realize that new platforms and technologies will be more effective."
          articleDescriptionUseCase="Some of the most effective decision-making processes include building consensus. Working together allows employees to share their ideas while understanding how their team members think, work, and operate. As a result, collaboration proves to be mutually beneficial for both the organization and its employees."
          articleSubtDescriptionUseCaseHigher="By design, the Agreed platform brings everyone together from every department, facilitating collaboration and consensus internally. Validators from these departments can easily and quickly upload all the necessary information from Finance or HR into the designated phases of a business strategy. Meanwhile, executives will be invited to take a look at the work that’s being done and chime in with their own requests and questions about the plan that’s being developed."
        />
        <ArticleBusinessComponent
          articleTitle={articleTheBlueprintTitle}
          articleText={articleTheBlueprintText}
          articleDescription={articleTheBlueprintDescription}
          articleSubText={articleTheBlueprintSubDescription}
        />
        <ImprovementsComponent
          improvementsTitle={articleImprovementsTitle}
          improvementsSubTitle={articleImprovementsText}
          improvementsDescriptionCol={articleImprovementsDescriptionCol}
          improvementsSubDescriptionCol={articleImprovementsSubDescriptionCol}
          improvementsTextCol={articleImprovementsTextCol}
          improvementsText={articleImprovementsDescription}
          improvementsSubText={articleImprovementsDescriptionHealthcare}
          improvementsDescription={articleImprovementsSubText}
          improvementsSubDescription={articleImprovementsSubDescription}
          ImprovementsImage={articleImprovementsImage}
        />
        <ArticleBusinessComponent
          articleTitle={articleOutcomeTitle}
          articleText={articleOutcomeText}
          articleDescription={articleOutcomeSubtext}
          articleSubText={articleOutcomeDescription}
        />
        <Footer />
      </Layout>
    );
}
export const query = graphql`
  query {
    wpPage(slug: { eq: "businessdeveloper-usecase" }) {
      article {
        articleTheBlueprintTitle
        articleTheBlueprintText
        articleTheBlueprintSubDescription
        articleTheBlueprintDescription
      }
      articleHomeSection {
        articleHomeTitle
        articleHomeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      articleBusinessSection {
        articleBusinessTitle
        articleBusinessSubtitle
        articleBusinessDescription
        articleBusinessText
      }
      articleChallengeSection {
        articleChallengeTitle
        articleChallengeText
        articleChallengeSubText
        articleChallengeDescription
        articleChallengeSubDescription
        articleChallengeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      articleMotivation {
        articleMotivationTitle
        articleMotivationText
        articleMotivationDescription
      }
      articleImprovementsSection {
        articleImprovementsTitle
        articleImprovementsText
        articleImprovementsTextCol
        articleImprovementsSubText
        articleImprovementsSubDescriptionCol
        articleImprovementsSubDescription
        articleImprovementsDescriptionHealthcare
        articleImprovementsDescriptionCol
        articleImprovementsDescription
        articleImprovementsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      articleOutcomeSection {
        articleOutcomeTitle
        articleOutcomeText
        articleOutcomeDescription
        articleOutcomeSubtext
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;
export default BusinessDeveloperUseCase;